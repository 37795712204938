
  import { mapGetters } from 'vuex';
  import { STORE_DISCOUNT_TYPES } from '../utils/constants';
  import Cookies from 'js-cookie';
  import { CODE_MESSAGES } from '../constants';

  let cachedCodes = null;

  export default {
    name: 'RedemptionCodeModal',
    data() {
      return {
        inputCode: null,
        currentCode: null,
        loading: false,
        codes: [],
        showModal: false,
        errorMessage: '',
      };
    },
    computed: {
      ...mapGetters({ storeInformation: 'storeInformation', loggedInCustomer: 'auth/loggedInUser' }),
    },
    async mounted() {
      await this.checkAndShowModal();
    },
    watch: {
      $route(to, from) {
        this.checkAndShowModal();
      },
    },
    methods: {
      async checkAndShowModal() {
        if (typeof window === 'undefined') return;
        if (
          this.storeInformation?.requireRedemptionCode &&
          this.storeInformation?.requireSignIn &&
          !this.loggedInCustomer
        ) {
          this.showModal = false;
          return;
        }
        await this.onFetchCodes();
        const storedCode = Cookies.get('REDEMPTION_CODE');
        if (this.codes.length === 0) {
          this.showModal = false;
          return;
        }
        this.showModal = !storedCode;
        if (this.showModal) return;
        try {
          const parsedCode = JSON.parse(storedCode);
          const matchedCode = this.codes.find((code) => code.code === parsedCode.code);
          this.showModal = !matchedCode;
          if (matchedCode) this.currentCode = matchedCode;
        } catch (e) {
          this.showModal = true;
        }
      },
      onCheckCode() {
        if (!this.inputCode) {
          return !!this.inputCode;
        }
        const matchedCode = this.codes.find((code) => code.code.toLowerCase() === this.inputCode.toLowerCase());
        if (matchedCode) {
          const isApplied =
            matchedCode.appliedBy?.length && matchedCode.appliedBy?.includes(this.loggedInCustomer?._id.toString());
          if (!matchedCode.conditions?.multipleCustomerUse && isApplied) {
            this.currentCode = null;
            this.errorMessage = CODE_MESSAGES.applied;
            return !!this.currentCode;
          }
          this.currentCode = matchedCode;
          this.errorMessage = '';
          return !!this.currentCode;
        } else {
          this.currentCode = null;
          this.errorMessage = CODE_MESSAGES.invalid;
          return !!this.currentCode;
        }
      },
      async onFetchCodes() {
        if (cachedCodes && cachedCodes.length > 0) {
          this.codes = cachedCodes;
          return;
        }
        if (!this.storeInformation?._id || !this.storeInformation?.requireRedemptionCode) return;
        try {
          this.codes = (
            await this.$api.codes.getDiscountByCode(this.storeInformation?._id, {
              type: STORE_DISCOUNT_TYPES.REDEMPTIONS,
            })
          ).data;
          if (this.codes.length > 0) {
            cachedCodes = this.codes;
          }
        } catch (e) {
          this.notificateError(e.response?.data?.message || e.data?.message || e.message || 'Failed to fetch codes');
        }
      },
      onRedeemCode() {
        if (!this.onCheckCode()) {
          return;
        }
        Cookies.set('REDEMPTION_CODE', JSON.stringify(this.currentCode));
        this.closeModal();
      },
      closeModal() {
        this.showModal = false;
      },
    },
  };
