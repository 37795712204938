
  export default {
    name: 'MPowerLightBox',
    components: {
      LightBox: () => import('vue-image-lightbox'),
    },
    methods: {
      closeLightBox() {
        this.$emit('close');
        this.$refs.lightbox.closeLightBox();
      },
    },
  };
