
  import Vue from 'vue';

  export default {
    components: {
      MPowerLightBox: () => import('./MPowerLightBox'),
    },
    data() {
      return {
        mediaFiles: [],
        currentFileIndex: null,
      };
    },
    computed: {
      currentFile() {
        return this.mediaFiles[this.currentFileIndex];
      },
      lightBoxMedia() {
        return this.mediaFiles
          .map((file) => {
            const src = file.fileThumbLocation || file.fileLocation || file.locationResized || file.location;
            return { src, thumb: src, ...file };
          })
          .filter((image) => !!image.src);
      },
      downloadUrl() {
        const url = encodeURIComponent(this.currentFile.fileLocation || this.currentFile.location);
        const filename = encodeURIComponent(this.currentFile.fileName);
        return `${this.$axios.defaults.baseURL}/download-file?url=${url}&filename=${filename}`;
      },
    },
    mounted() {
      Vue.prototype.$previewFiles = this.previewFiles;
      this.enableWatchLightBoxOn();
      this.enableWatchLightBoxSelect();
    },
    methods: {
      previewFiles(files, index) {
        this.currentFileIndex = index;
        this.mediaFiles = files;
        this.$nextTick(() => {
          this.$refs.lightbox?.$refs.lightbox.showImage(index);
        });
      },
      reset() {
        this.mediaFiles = [];
        this.currentFileIndex = null;
      },
      enableWatchLightBoxOn() {
        this.$watch(
          () => this.$refs.lightbox?.$refs.lightbox.lightBoxOn,
          (state) => {
            if (!state) {
              this.reset();
            }
          }
        );
      },
      enableWatchLightBoxSelect() {
        this.$watch(
          () => this.$refs.lightbox?.$refs.lightbox.select,
          (index) => {
            this.currentFileIndex = index;
          }
        );
      },
    },
  };
